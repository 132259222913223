<script setup lang="ts">
import { getSeoObject } from "~/helpers/seo";

const { data } = useCustomFetch("/api/strapi/platforms", {
  query: { "filters[platform][$eq]": "app" },
});

const platform = computed(() => data.value?.[0]?.attributes);
const faqs = computed(() => data.value?.[0]?.attributes?.faqs);

// useSeoMeta(getSeoObject(platform.value?.seo));
</script>

<template>
  <section v-if="platform" id="maincontentblock" class="apppage">
    <SeoMeta v-if="platform?.seo" v-bind="platform?.seo" />
    <div
      class="blureffect goesleft"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1500"
    ></div>

    <div
      class="blureffect goesright"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1500"
    ></div>

    <div class="platformblock">
      <div class="container">
        <div class="row">
          <div class="col-md-5 app-col-md-5">
            <h2 class="title" style="white-space: pre-line">
              {{ platform.heroSection.title }}
            </h2>
            <p class="titledesci" style="white-space: pre-line">
              {{ platform.heroSection.description }}
            </p>
            <div class="page-hdr-buttons">
              <NuxtLinkLocale
                v-for="button in platform.heroSection.buttons"
                :key="button.id"
                :to="button.url"
                class="buttons"
                :target="button.openInNewTab ? '_blank' : '_self'"
              >
                {{ button.name }}
              </NuxtLinkLocale>
            </div>
          </div>

          <div class="col-md-7 app-col-md-7">
            <img
              alt=""
              :src="$getStrapiImageSrc(platform.heroSection.image)"
              class="img-fluid webtraderblockimg app-header-img app-stockimgs"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="elevateblock">
      <div class="container">
        <div
          class="candle candle-app"
          data-aos="zoom-in-up"
          data-aos-delay="200"
        ></div>

        <div class="row headingblock app-headingblock">
          <div class="col-md-12">
            <h2 v-html="$mdRenderer.renderInline(platform.enhanceTitle)"></h2>

            <p
              class="justifycontent app-justifycontent"
              style="white-space: pre-line"
            >
              {{ platform.enhanceDescription }}
            </p>

            <NuxtLinkLocale
              v-for="button in platform.enhanceButtons"
              :key="button.id"
              :to="button.url"
              class="buttons"
              :target="button.openInNewTab ? '_blank' : '_self'"
            >
              {{ button.name }}
            </NuxtLinkLocale>
          </div>
        </div>
        <div class="portraitmobileblock">
          <div
            class="blureffect middleblock"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          ></div>
          <div class="row blocking">
            <div class="col-md-12">
              <img
                alt=""
                :src="$getStrapiImageSrc(platform.phoneBlockImage)"
                class="img-fluid portraitmobile"
              />
            </div>
          </div>

          <div class="row threeblocks modifiedbox app-modifiedbox">
            <div
              v-for="card in platform.phoneBlockItems"
              :key="card.id"
              class="col-md-3"
            >
              <div
                class="glassblock"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay="300"
              >
                <img
                  alt=""
                  height="70"
                  :src="$getStrapiImageSrc(card.icon)"
                  class="icon"
                />
                <h2>{{ card.title }}</h2>
                <p>{{ card.text }}</p>
              </div>
            </div>
          </div>

          <div class="row threeblocks modifiedbox app-modifiedbox-tablet">
            <div
              v-for="card in platform.phoneBlockItems"
              :key="card.id"
              class="col-md-12"
            >
              <div
                class="glassblock"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay="300"
              >
                <img
                  alt=""
                  height="70"
                  :src="$getStrapiImageSrc(card.icon)"
                  class="icon"
                />
                <h2>{{ card.title }}</h2>
                <p>{{ card.text }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="wraperblockd modifiedwrapper">
          <div
            class="blureffect leftblocked"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          ></div>

          <div
            class="blureffect centerblocked"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          ></div>

          <div
            class="blureffect rightblocked"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          ></div>
          <div
            v-if="platform.benefitsItems"
            class="blockglasser app-blockglasser"
          >
            <div class="row">
              <div class="col-md-12">
                <h2 class="titleblock">{{ platform.benefitsTitle }}</h2>
              </div>
            </div>

            <div class="row">
              <div
                v-for="item in platform.benefitsItems"
                :key="item.id"
                class="col-xs-12 col-lg-6"
              >
                <div class="glassblock">
                  <img :src="$getStrapiImageSrc(item.icon)" class="iconblock" />
                  <h2>{{ item.title }}</h2>
                  <p>{{ item.text }}</p>
                </div>
              </div>
            </div>

            <NuxtLinkLocale
              v-for="button in platform.benefitsButtons"
              :key="button.id"
              :to="button.url"
              class="buttons"
              :target="button.openInNewTab ? '_blank' : '_self'"
            >
              {{ button.name }}
            </NuxtLinkLocale>
          </div>
        </div>
      </div>
    </div>
  </section>

  <JoinCta />

  <FaqItems :items="faqs" />
</template>
